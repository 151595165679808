import { API_HOST_DUMMY_URL, USER_INFO } from "../config/constants";

// Fetch.js
async function request(url, params, method = "GET", responseType = undefined) {
  let token = "";
  let currentUser = localStorage.getItem(USER_INFO);
  if (currentUser != null) {
    token = JSON.parse(currentUser).token;
  }
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (url.includes(API_HOST_DUMMY_URL)) {
    options.headers["x-maf-canary"] = "disabled";
  }

  if (responseType) {
    options.responseType = responseType;
  }

  if (params) {
    if (method === "GET") {
      url += "?" + objectToQueryString(params);
    } else {
      options.body = JSON.stringify(params);
    }
  }
  const response = await fetch(url, options);
  return response;
}

function objectToQueryString(obj) {
  let queryString = "";
  for (const [key, value] of Object.entries(obj)) {
    if (value != null) {
      if (queryString === "") {
        queryString = key + "=" + value;
      } else {
        queryString = queryString + "&" + key + "=" + value;
      }
    }
  }
  return queryString;
}

function get(url, params) {
  return request(url, params);
}

function create(url, params) {
  return request(url, params, "POST");
}

function update(url, params) {
  return request(url, params, "PUT");
}

function remove(url, params) {
  return request(url, params, "DELETE");
}

function patch(url, params) {
  return request(url, params, "PATCH");
}

function exportRequest(url, params, responseType) {
  return request(url, params, "GET", responseType);
}

// eslint-disable-next-line
export default {
  get,
  create,
  update,
  remove,
  patch,
  exportRequest,
};
